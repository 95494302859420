import React, { useEffect, useState } from 'react'
import DefaultTemplate from '../../../../components/Layouts/DefaultTemplate'
import MediaTemplate from '../../../../components/Layouts/MediaTemplate'
import { useMutation } from 'react-query'
import { useRouter } from 'next/router'
import pushToExternalRoute from '../../../../utils/pushToExternalRoute'
import { ReaderAPI } from '../../../../repository'
import { format } from 'date-fns'

import getCampaignMiddleware from '../../../../middleware/getCampaignMiddleware'
import utils from '../../../../utils'
import BrowserNotSupportedMessage from '../../../../components/BrowserNotSupportedMessage'
const serverSideUtils = utils.serverSideUtils

export default Page

function Page({ page, campaign, isNotSupportedBrowser, errors }) {
  const router = useRouter()
  const queryString = utils.getQueryStringFromUrl(
    decodeURIComponent(router?.asPath),
  )

  const onSubmitFormSuccess = ({ campaign, page, router }) => (response) => {
    const findPageById = (campaign, pageId) =>
      campaign?.pages?.find((page) => page._id === pageId)

    const nextPage = page?.settings?.nextPage
    const nextCampaignPage = findPageById(campaign, nextPage)
    const query = {
      data: JSON.stringify({
        ...(campaign?.isAutopopulatedEnabled ? response?.data?.data : {}),
        email: response?.data?.email,
        session_id: response?.data?.session_id,
        correct_answers: response?.data?.data?.correct_answers,
        total_answers: response?.data?.data?.total_answers,
      }),
    }
    const queryParams = utils.getQueryStringFromUrl(router.asPath)

    nextCampaignPage
      ? router.push(
          { pathname: router.pathname, query },
          router.asPath.replace(
            /\/[^/]+$/,
            `/${nextCampaignPage.slug}${queryParams}`,
          ),
        )
      : pushToExternalRoute(router)(nextPage)
  }

  const submitForm = ({ campaign, page, router }) => async (formData) => {
    const formatFormData = (formData, formQuestions) => {
      let formattedData = {}
      for (const [key, value] of Object.entries(formData)) {
        if (
          typeof value === 'object' &&
          !Array.isArray(value) &&
          value !== null &&
          !(
            Object.keys(value).includes('label', 'value') &&
            Object.keys(value).length === 2
          )
        ) {
          formattedData[key + '.' + Object.keys(value)[0]] =
            value[Object.keys(value)[0]]
        } else {
          formattedData[key] = value
        }
      }
      formattedData = formatSelectQuestions(formattedData, formQuestions)
      formattedData = formatQuizQuestions(formattedData)
      return formattedData
    }

    const getFormBlock = (page) =>
      page?.layout.find((element) => element.type === 'form')

    const formatDate = (dateInput) =>
      new Date(dateInput).toISOString().slice(0, 10)

    const formatSelectQuestions = (formData, formQuestions) => {
      const result = {}
      Object.keys(formData).forEach((key) => {
        const data = ['date', 'entry_date'].includes(
          formQuestions.find((question) => question.name === key)?.type,
        )
          ? formatDate(formData[key])
          : formData[key]
        const { value } = data || {}
        result[key] = value || data
      })
      return result
    }
    const formatQuizQuestions = (formData) => {
      const getNumQuizzes = (questions, quizzes = 0) => {
        questions.forEach((question) => {
          if (question.isQuiz) quizzes++
          if (question.dependentQuestions) {
            const children = question.dependentQuestions.map(
              (dependentQuestion) => dependentQuestion.question,
            )
            quizzes = getNumQuizzes(children, quizzes)
          }
        })
        return quizzes
      }

      const getNumCorrectAnswers = (questions, formData, answers = 0) => {
        questions.forEach((question) => {
          if (
            ['select', 'radio', 'multiple_checkboxes'].includes(
              question?.type,
            ) &&
            !!question?.isQuiz
          ) {
            const value = formData[question.name]

            if (question?.type === 'multiple_checkboxes') {
              const options = question.config.options.filter((o) =>
                value.includes(o.value),
              )
              if (options) options.every((o) => o.correct) && answers++
            }
            const option =
              question?.type === 'radio'
                ? question.options.find((o) => o.value === value)
                : question.config.options.find((o) => o.value === value)

            if (option && option.correct) answers++
          }

          if (question.dependentQuestions) {
            const children = question.dependentQuestions.map(
              (dependentQuestion) => dependentQuestion.question,
            )
            answers = getNumCorrectAnswers(children, formData, answers)
          }
        })
        return answers
      }

      const questions = getFormBlock(page)?.content?.questions

      const total_answers = getNumQuizzes(questions)
      const correct_answers = getNumCorrectAnswers(questions, formData)

      return { ...formData, correct_answers, total_answers }
    }

    const getSessionId = () =>
      router?.query?.data
        ? JSON.parse(router?.query?.data)?.session_id
        : utils.getUuid()
    const formQuestions = getFormBlock(page)?.content?.questions
    const formattedFormData = formatFormData(formData, formQuestions)
    const formId = getFormBlock(page)?._id

    const email =
      formattedFormData?.email ||
      utils.safeJSONParse(router?.query?.data)?.email

    const session_id = getSessionId()

    const getUtmParams = () => ({
      utm_source: router?.query?.utm_source,
      utm_medium: router?.query?.utm_medium,
      utm_campaign: router?.query?.utm_campaign,
      utm_content: router?.query?.utm_content,
      utm_term: router?.query?.utm_term,
    })

    const getUserLocaleKey = (formQuestions) => {
      let userLocaleKey
      formQuestions.forEach((question) => {
        if (question.type === 'user_locale') {
          userLocaleKey = question.name
        }
      })

      if (userLocaleKey === undefined) {
        return {}
      }

      return { [userLocaleKey]: router?.query?.locale }
    }

    const getDateOfEntryKey = (formQuestions) => {
      let dateOfEntryKey
      formQuestions.forEach((question) => {
        if (question.type === 'entry_date') dateOfEntryKey = question.name
      })

      if (dateOfEntryKey === undefined) return {}
      const entryDate = format(new Date(), 'yyyy-MM-dd')

      return { [dateOfEntryKey]: entryDate }
    }

    const userLocaleData = getUserLocaleKey(formQuestions)
    const dateOfEntryData = getDateOfEntryKey(formQuestions)

    const formatPayload = {
      email,
      session_id,
      source_id: formId,
      page_id: page._id,
      data: {
        ...formattedFormData,
        ...userLocaleData,
        ...dateOfEntryData,
        email,
        locale: router?.query?.locale,
        ...getUtmParams(),
      },
    }

    if (campaign?.facebookConversion?.enabled) {
      ReaderAPI.trackFacebookConversion()({
        body: {
          eventName:
            page.slug === 'survey'
              ? 'Complete survey'
              : 'Complete registration',
          userData: formatPayload,
          campaignSlug: campaign.slug,
        },
      })
    }
    if (document.getElementsByClassName('file-upload-block')?.length > 0) {
      if (
        !fileToUpload &&
        document.getElementsByClassName('file-upload-block-required')?.length >
          0
      ) {
        throw new Error('File not selected')
      }
      if (fileToUpload) {
        // Upload file before submiting contact
        await ReaderAPI.uploadFile()({
          body: fileToUpload.file,
          campaignId: campaign._id,
          email,
          fileType: fileToUpload.fileType,
        })
      }
    }
    return ReaderAPI.sendSubmitForm()({
      body: formatPayload,
      campaignId: campaign._id,
    })
  }

  const [fileToUpload, setFileToUpload] = useState(null)

  const setFile = (file) => {
    setFileToUpload(file)
  }
  useEffect(() => {
    if ('parentIFrame' in window) {
      // eslint-disable-next-line no-undef
      window.parentIFrame.scrollTo(0, 0)
    }
  }, [page])

  const params = utils.getQueryParamsFromUrl(queryString)

  useEffect(() => {
    const form = document.querySelectorAll('form')[0]

    const callback = () => {
      const queryParams = {
        ...params,
        ...(router?.query?.data ? JSON.parse(router?.query?.data) : {}),
      }
      Object.keys(queryParams).forEach((k) => {
        const input = document.getElementById(k)
        if (input && input.value === '') {
          input.value = queryParams[k]
        }
      })
    }
    if (form) {
      callback() // Execute at least once
      const observer = new MutationObserver(callback)
      observer.observe(form, { childList: true, subtree: true })

      return () => observer.disconnect()
    }
  }, [params, router])

  const [sendForm, { error: formError }] = useMutation(
    submitForm({
      campaign,
      page,
      params,
      router,
    }),
    {
      onSuccess: onSubmitFormSuccess({
        campaign,
        page,
        params,
        router,
      }),
    },
  )

  if (errors?.length) {
    return (
      <h1 data-cy="campaign-request-failure">
        Something went wrong loading this page
      </h1>
    )
  }

  const mapTemplateToLayouts = (page) => {
    return {
      single_competition: (
        <DefaultTemplate
          formError={formError}
          sendForm={sendForm}
          setUploadFile={setFile}
          campaign={campaign}
          page={page}
        />
      ),
      competition_survey: (
        <DefaultTemplate
          formError={formError}
          sendForm={sendForm}
          setUploadFile={setFile}
          campaign={campaign}
          page={page}
        />
      ),
      voting_competition: (
        <DefaultTemplate
          sendForm={sendForm}
          setUploadFile={setFile}
          campaign={campaign}
          page={page}
        />
      ),
      single_video: (
        <DefaultTemplate
          sendForm={sendForm}
          setUploadFile={setFile}
          campaign={campaign}
          page={page}
        />
      ),
      media_competition: (
        <MediaTemplate sendForm={sendForm} campaign={campaign} page={page} />
      ),
      default: null,
    }
  }

  return isNotSupportedBrowser ? (
    <BrowserNotSupportedMessage />
  ) : (
    mapTemplateToLayouts(page)[campaign?.template] ||
      mapTemplateToLayouts(page)['default']
  )
}

const getPageAndTheme = async (campaignId, pageSlug, locale) => {
  const [pageResults, errors] = await utils.requester([
    ReaderAPI.getPageBySlug({
      domain: '',
      campaignId: campaignId,
      pageSlug,
      locale,
    }),
    ReaderAPI.getTheme({
      domain: '',
      campaignId: campaignId,
    }),
  ])
  const [page, theme] = pageResults
  return {
    results: [page?.value?.data, theme?.value?.data],
    errors,
  }
}

const trackPageVisit = (campaignId, pageId) =>
  ReaderAPI.trackEvent('')({
    campaignId: campaignId,
    event_name: 'page_visit',
    data: {
      pageId: pageId,
    },
  })

const redirectWhenInvalidLocale = (ctx, urlLocale, campaign, pageSlug) => {
  const locale = utils.isValidLocaleOrDefault({
    campaign: campaign,
    locale: urlLocale,
  })
  if (locale !== urlLocale) {
    serverSideUtils.redirectToPage({
      ctx,
      campaignSlug: campaign?.slug,
      locale,
      pageSlug,
    })
  }
}

const getProps = async ({ ctx, locale, campaignData, pageSlug }) => {
  redirectWhenInvalidLocale(ctx, locale, campaignData, pageSlug)

  const { page, campaign, locale: queryLocale, data } = ctx.query
  const jsonData = data !== undefined ? JSON.parse(data) : null

  if (page === 'survey' && !data && !jsonData?.email && !jsonData?.session_id) {
    serverSideUtils.redirectToPage({
      ctx,
      campaignSlug: campaign,
      locale: queryLocale,
      pageSlug: 'landing',
    })
  }
  const {
    results: [pageData, themeData],
    errors: pageErrors,
  } = await getPageAndTheme(campaignData?._id, pageSlug, locale)

  if (!pageData) {
    return {
      notFound: true,
    }
  }

  if (
    jsonData?.correct_answers !== undefined &&
    jsonData?.total_answers !== undefined
  ) {
    pageData.layout = pageData.layout.map((l) => {
      const text = l?.content?.text
      if (text !== undefined)
        l.content.text = l.content.text
          .replace('${correct_answers}', jsonData?.correct_answers)
          .replace('${total_answers}', jsonData?.total_answers)
      return l
    })
  }

  try {
    await trackPageVisit(campaignData?._id, pageData._id)
  } catch (err) {
    console.error('Error tracking the page visit', err?.response?.data)
  }

  return {
    props: {
      errors: pageErrors,
      campaign: campaignData,
      page: pageData,
      uploadedTheme: themeData,
      isNotSupportedBrowser: serverSideUtils.isNotSupportedBrowser(ctx),
    },
  }
}

export const getServerSideProps = getCampaignMiddleware(getProps)
